const getSupportedMimeType = () => {
    const mimeTypes = [
      'video/mp4; codecs="avc1.4D002A"',
      'video/webm; codecs="vp9"',
      'video/webm; codecs="vp8"',
    ];
    if (typeof MediaRecorder === "undefined" || !MediaRecorder.isTypeSupported) {
      throw new Error("MediaRecorder API is not supported in this browser.");
    }
    for (const mimeType of mimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        return mimeType;
      }
    }
    // Return undefined to let the browser choose its default MIME type
    return undefined;
  };
  export default getSupportedMimeType;
